import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import ProgressTracker from "../components/ProgressTracker"
import Intro from "../components/Intro"
import UserForm from "../components/forms/UserForm"

const IndexPage = () => (
  <Layout>
    <SEO title="Details" />
    <ProgressTracker currentStep={1} />
    <Intro title="First, let’s get to know you" />
    <UserForm />
  </Layout>
)

export default IndexPage
