import React from "react"
import styled from "styled-components"
import { Field } from "formik"

// Styling
import colors from "../../../styles/colors"

// Components
import FieldError from "./FieldError"
import Icon from "../../icons/Icon"

interface IOptions {
  value: string
  label: string
}

interface IProps {
  label: string
  name: string
  options: IOptions[]
  error: string
  touched: boolean
}

const FieldSelect = ({ label, name, options, error, touched }: IProps) => (
  <Container>
    <Label>{label}</Label>
    <Select>
      <StyledIcon
        icon="chevronDown"
        color={colors.lightGrey}
        width={18}
        height={18}
      />
      <Field as={StyledField} name={name}>
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </Field>
    </Select>
    {error && touched && <StyledFieldError error={error} />}
  </Container>
)

const Container = styled.div`
  margin-bottom: 32px;
`

const Label = styled.label`
  display: block;
  margin-bottom: 12px;
  font-weight: 600;
`

const Select = styled.div`
  position: relative;
`

const StyledIcon = styled(Icon)`
  cursor: pointer;
  z-index: 1;
  position: absolute;
  top: 50%;
  right: 18px;
  transform: translateY(-50%);
`

const StyledField = styled.select`
  position: relative;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  padding: 18px 53px 18px 18px;
  border-radius: 4px;
  border: none;
  background-color: ${colors.white};
  box-shadow: 0 2px 4px 0 rgba(169, 169, 169, 0.25);
`

const StyledFieldError = styled(FieldError)`
  display: block;
  margin-top: 8px;
`

export default FieldSelect
