import React, { useState } from "react"
import styled from "styled-components"
import { navigate } from "gatsby"
import { Formik, Form } from "formik"
import * as Yup from "yup"
import localForage from "localforage"

// Components
import FieldSelect from "./fields/FieldSelect"
import PageNavigation from "../PageNavigation"

const UserForm = () => {
  const initialValues = {
    role: "",
    organization: "",
    experience: "",
    username: "",
  }

  const validationSchema = Yup.object().shape({
    role: Yup.string().required("Please select your role"),
    organization: Yup.string().required("Please select your organization"),
    experience: Yup.string().required(
      "Please select if you have any experience working with a design system"
    ),
    username: Yup.string().notRequired(),
  })

  const handleNextStep = async (values: any) => {
    const currentData = await localForage.getItem("data")
    const updatedData = {
      ...currentData,
      role: values.role,
      organization: values.organization,
      experience: values.experience,
      fakeUsername: values.username,
    }
    localForage.setItem("data", updatedData, () => {
      navigate("/themes")
    })
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={values => handleNextStep(values)}
    >
      {({ errors, touched }) => (
        <StyledForm>
          <FieldSelect
            label="What is your role?"
            name="role"
            error={errors.role}
            touched={touched.role}
            options={[
              {
                value: "",
                label: "Select your role",
              },
              {
                value: "UX/Designer",
                label: "UX/Designer",
              },
              {
                value: "Developer/Architect",
                label: "Developer/Architect",
              },
              {
                value: "Consultant",
                label: "Consultant",
              },
              {
                value: "Teamlead/Projectmanager",
                label: "Teamlead/Projectmanager",
              },
              {
                value: "Management/Board",
                label: "Management/Board",
              },
              {
                value: "Other",
                label: "Other",
              },
            ]}
          />
          <FieldSelect
            label="Within which organization?"
            name="organization"
            error={errors.organization}
            touched={touched.organization}
            options={[
              {
                value: "",
                label: "Select your organization",
              },
              {
                value: "Rijksoverheid | Central Government",
                label: "Rijksoverheid | Central Government",
              },
              {
                value: "Gemeente | Local government",
                label: "Gemeente | Local government",
              },
              {
                value: "Uitvoeringsorganisatie | Implementing organization",
                label: "Uitvoeringsorganisatie | Implementing organization",
              },
              {
                value: "Non-government",
                label: "Non-government",
              },
              {
                value: "Other",
                label: "Other",
              },
            ]}
          />
          <FieldSelect
            label="Do you have experience working with a design system?"
            name="experience"
            error={errors.experience}
            touched={touched.experience}
            options={[
              {
                value: "",
                label: "Make a choice",
              },
              {
                value: "Yes",
                label: "Yes",
              },
              {
                value: "No",
                label: "No",
              },
            ]}
          />
          {/* Fake honeypot username field below for bots detection */}
          <HoneypotBotsField type="text" id="username" name="username" />
          <PageNavigation label="Continue" from="/" />
        </StyledForm>
      )}
    </Formik>
  )
}

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const HoneypotBotsField = styled.input`
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: -1;
`

export default UserForm
